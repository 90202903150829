import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../lib/constants';
import { Spinner } from '../components/spinner/Spinner';
import { getURLParams } from '../lib/getUrlParams';
import { useAuth0Token } from '../hooks/useAuth0Token';
import { actions } from '../redux/modules/myMembership';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import { isInternalLink } from '../lib/isInternalLink';
import useSelector from '../redux/typedHooks';

export const LoggingIn = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  useEffect(() => {
    const params = getURLParams();
    const { isInternal, relativeLink } = isInternalLink(baseUrl, params?.redirect);

    if (params?.redirect) {
      if (isInternal) {
        history.push(relativeLink);
      }
    }
  }, [isAuthenticated, history, baseUrl]);

  useEffect(() => {
    if (simpleToken) {
      dispatch(actions.getMyMembership.request(simpleToken, {}));
    }
  }, [simpleToken, dispatch]);

  return (
    <LayoutWithMainContent
      title="Logger inn..."
      description="Logger inn på Mitt NAF"
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Logger inn',
        pageCategory: '',
        contentId: 'logging-in',
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      noIndex
    >
      <div>Du blir logget inn</div>
      {isLoading && <Spinner />}
    </LayoutWithMainContent>
  );
};
